/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import React
import React, { useState, useEffect } from 'react';
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from "@mui/material";
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PdfViewer from "examples/PdfViewer";
// import ReportsService from "services/reports-service";
import PendingAmountService from "services/reports-pending-amount";

// Helpers
import Helpers from 'utils/helpers';

function PendingAmount() {
  const [dataResponse, setDataResponse] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [isShowPdf, setIsShowPdf] = useState(false);
  const [selectedValue, setSelectedValue] = useState("GIB");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortOrderFields, setSortOrderFields] = useState({
    sort_by: "tenant_code",
    order: "asc"
  })

  useEffect(() => {
    // Lấy ngày hiện tại
    const today = new Date();
    
    // Tính toán start date (ngày 15 của tháng trước)
    const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 15);
    
    // Tính toán end date (ngày 15 của tháng này)
    const currentMonth = new Date(today.getFullYear(), today.getMonth(), 15);


    // Đặt giá trị mặc định cho start date và end date
    setStartDate(Helpers.formatDate(previousMonth));
    setEndDate(Helpers.formatDate(currentMonth));
  }, []);

  const validateDate = (start, end) => {
    const startDateFormat = dayjs(start);
    const endDateFormat = dayjs(end);

    if (!startDateFormat.isValid() || !endDateFormat.isValid()) {
      setErrorMessage("Invalid date format");
      setError(true);
      setButtonDisabled(true);
      return;
    }
    if (start && end && startDateFormat.isAfter(endDateFormat)) {
      setErrorMessage("Start date cannot be greater than end date");
      setError(true);
      setButtonDisabled(true);
      return;
    }
    setErrorMessage("");
    setError(false);
    setButtonDisabled(false);
  }

  const fetchPdfFile = async (payload) => {
    try {
        const response = await PendingAmountService.downloadPdfFile(payload);
        setDataResponse(response);
    } catch (error) {
        console.error('Error fetching PDF:', error);
    }
  };

  const onChangeSelect = (event) => {
    const { value, name } = event.target;
    if (name === "property_code") {
      setSelectedValue(value);
    } else {
      setSortOrderFields({
        ...sortOrderFields,
        [name]: value
      });
    }
  }

  const onHandleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      property_id: "c96a2dcb-58c7-4008-9b0d-43c3ee073822",
      period_id: "d7b86783-3b82-4d25-b70a-d28657dc8079",
      property_code: selectedValue,
      start_date: startDate,
      end_date: endDate,
      sort_by: sortOrderFields.sort_by,
      order: sortOrderFields.order
    }

    setButtonDisabled(true);
    fetchPdfFile(payload);
    setIsShowPdf(true);
    setPdfFile(null);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Reports
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2} sx={{ padding: "19px 20px"}}>
                  <Grid item xs={12} md={4}>
                    <MDBox component="form" role="form" method="POST" onSubmit={onHandleSubmit}>
                      <MDBox mb={2}>
                        <FormControl fullWidth>
                            <InputLabel
                                id="property-code-select-label"
                            >
                                Property code
                            </InputLabel>
                            <Select
                                labelId="property-code-select-label"
                                name="property_code"
                                value={selectedValue}
                                onChange={onChangeSelect}
                                displayEmpty
                                label="Property code"
                                sx={{
                                    minWidth: 200,
                                    height: '44px', // Set chiều cao cố định
                                }}
                                MenuProps={{
                                  disableScrollLock: true, // Ngăn MUI tự động thêm padding-right vào <body>
                                }}
                            >
                                <MenuItem value={"GIB"}>GIB</MenuItem>
                                <MenuItem value={"YLC"}>YLC</MenuItem>
                                <MenuItem value={"YL3"}>YL3</MenuItem>
                            </Select>
                        </FormControl>
                      </MDBox>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MDBox mb={2}>
                          <DatePicker
                            label="Start date"
                            value={startDate ? dayjs(startDate) : null} // Convert to dayjs object
                            onChange={(newValue) => {
                              setStartDate(newValue ? newValue.format("YYYY-MM-DD") : ""); // Store as string
                              validateDate(newValue ? newValue.format("YYYY-MM-DD") : "", endDate);
                            }}
                            maxDate={endDate ? dayjs(endDate) : null} // Convert to dayjs object
                            renderInput={(params) => (
                              <TextField {...params} name="startDate" helperText={errorMessage} error={error} fullWidth className="w-100" />
                            )}
                            format="YYYY-MM-DD"
                            className="w-100"
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <DatePicker
                            label="End date"
                            value={endDate ? dayjs(endDate) : null} // Convert to dayjs object
                            onChange={(newValue) => {
                              setEndDate(newValue ? newValue.format("YYYY-MM-DD") : ""); // Store as string
                              validateDate(startDate, newValue ? newValue.format("YYYY-MM-DD") : "");
                            }}
                            minDate={startDate ? dayjs(startDate) : null} // Convert to dayjs object
                            renderInput={(params) => (
                              <TextField {...params} name="endDate" helperText={errorMessage} error={error} fullWidth className="w-100" />
                            )}
                            format="YYYY-MM-DD"
                            className="w-100"
                          />
                        </MDBox>
                      </LocalizationProvider>
                      <MDBox mb={2}>
                        <FormControl fullWidth>
                            <InputLabel
                                id="sort-by-select-label"
                            >
                                Sort by
                            </InputLabel>
                            <Select
                                labelId="sort-by-select-label"
                                name="sort_by"
                                value={sortOrderFields.sort_by}
                                onChange={onChangeSelect}
                                displayEmpty
                                label="Sort by"
                                sx={{
                                    minWidth: 200,
                                    height: '44px', // Set chiều cao cố định
                                }}
                                MenuProps={{
                                  disableScrollLock: true, // Ngăn MUI tự động thêm padding-right vào <body>
                                }}
                            >
                                <MenuItem value={"tenant_code"}>Tenant code</MenuItem>
                                <MenuItem value={"invoice_no"}>Invoice number</MenuItem>
                            </Select>
                        </FormControl>
                      </MDBox>
                      <MDBox mb={2}>
                        <FormControl fullWidth>
                            <InputLabel
                                id="order-select-label"
                            >
                                Order
                            </InputLabel>
                            <Select
                                labelId="order-select-label"
                                name="order"
                                value={sortOrderFields.order}
                                onChange={onChangeSelect}
                                displayEmpty
                                label="Order"
                                sx={{
                                    minWidth: 200,
                                    height: '44px', // Set chiều cao cố định
                                }}
                                MenuProps={{
                                  disableScrollLock: true, // Ngăn MUI tự động thêm padding-right vào <body>
                                }}
                            >
                                <MenuItem value={"asc"}>Ascending</MenuItem>
                                <MenuItem value={"desc"}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                      </MDBox>
                      <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton
                          variant="gradient"
                          sx={{
                            padding: "12px 30px",
                            backgroundColor: "#388e3c",
                            color: "#ffffff",
                            borderRadius: "0.2rem",
                            "&:hover": {
                              backgroundColor: "#919191",
                              color: "#fff",
                            },
                            "&:disabled": {
                              background: "#e0e0e0"
                            }
                          }}
                          disabled={buttonDisabled}
                          type="submit"
                        >
                          Download
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <PdfViewer
                      dataResponse={dataResponse}
                      options={{ theme: 'dark', defaultScale: 1.5 }}
                      isShowPdf={isShowPdf}
                      pdfFile={pdfFile}
                      setPdfFile={setPdfFile}
                      setButtonDisabled={setButtonDisabled}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
    </DashboardLayout>
  );
}

export default PendingAmount;
