// import React
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import TenantsService from 'services/tenants-service';
import leasesService from 'services/leases-service';

function EditLeases(props) {
  const [errors, setErrors] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [rentAmount, setRentAmount] = useState("");
  const [leaseAddress, setLeaseAddress] = useState("");
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tenantValue, setTenantValue] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const { open, setOpen, setOpenNotification, rowData, setIsShow, setIsEdited, setErrorEdit } = props;

  const onHandleSubmit = async (event) => {
    event.preventDefault();
    const tenantData = rowData.tenants;
    const payload = {
      lease_id: rowData.id ,
      rent_amount: rentAmount,
      lease_address: leaseAddress,
      tenant_id: tenantValue?.id !== 0 ? tenantValue?.id || '' : 0,
      tenant_name: tenantValue?.label !== '' ? tenantValue?.label || '' : '',
    };
    console.log(payload);
    try {
      const response = await leasesService.updateLease(payload);
      console.log(response);
      setOpenNotification(true);
      setOpen(false);
      setIsEdited(true);
      setErrorEdit(false);
    } catch (error) {
      setError(error.message);
      setIsEdited(false);
      setErrorEdit(true);
      console.log('Request failed:', error);
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);  // Clear the error after 4 seconds
      }, 5000);

      // Cleanup the timer on component unmount or when error is cleared
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (rowData !== null && open) {
      setRentAmount(rowData.rent_amount);
      setLeaseAddress(rowData.agreement_doc);
    }
    const tenantData = rowData.tenants;
    if (tenantData && open) {
        if (tenantData && tenantData[0] && tenantData[0].id) {
          const tenantRow = {
            id: tenantData[0].id,
            label: tenantData[0].first_name
          };
          setTenantValue(tenantRow);
        } else {
          console.log("tenantData[0] or tenantData[0].id does not exist");
        }
      }
  }, [rowData, open]);

  useEffect(() => {
    if (rentAmount === "") {
      setErrorMessage("Rent amount field is required");
      setErrors(true);
    } else {
      setErrorMessage("");
      setErrors(false);
    }
  },[rentAmount]);

  const getTenantsList = async (payload) => {
    try {
      setLoading(true);
      const tenantsList = await TenantsService.getList(payload);
      const optionsData = tenantsList.map((item) => ({
        id: item.id,
        label: item.first_name
      }));
      setOptions(optionsData);
    } catch (error) {
      console.error('Error get tenants list:', error);
    } finally {
      setLoading(false);
    }
  }

  const onChangeRentAmount = (event) => {
    const { value } = event.target;
    setRentAmount(value);
  }
  const onChangeLeaseAddress = (event) => {
    const { value } = event.target;
    setLeaseAddress(value);
  }

  useEffect(() => {
    getTenantsList({ list: 'first_name,middle_name,last_name' });
  },[]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='md'>
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Icon >close</Icon>
        </IconButton>
      <DialogContent>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Edit Leases
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2} sx={{ padding: "19px 20px"}}>
                  <Grid item xs={12} md={12}>
                    <MDBox component="form" role="form" method="POST" onSubmit={onHandleSubmit}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Rent Amount"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          sx={{
                            "& .MuiInput-underline:after": { borderBottomColor: "#388e3c"},
                            "& .MuiInputLabel-root.Mui-focused": { color: "#388e3c" }
                          }}
                          value={rentAmount}
                          onChange={onChangeRentAmount}
                          name='rent_amount'
                        />
                        {( errorMessage && errors ) && (
                          <FormHelperText error>{errorMessage}</FormHelperText>
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Lease Address"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          sx={{
                            "& .MuiInput-underline:after": { borderBottomColor: "#388e3c"},
                            "& .MuiInputLabel-root.Mui-focused": { color: "#388e3c" }
                          }}
                          value={leaseAddress}
                          onChange={onChangeLeaseAddress}
                          name='lease_address'
                        />
                        {( errorMessage && errors ) && (
                          <FormHelperText error>{errorMessage}</FormHelperText>
                        )}
                      </MDBox>
                      <MDBox mt={4} mb={1} textAlign="right">
                        <Autocomplete
                          disableCloseOnSelect
                          options={options || []}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => {return value?.id && option.id === value.id;}}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tenants"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                          renderOption={(props, option, state) => (
                            <li {...props} key={option.id}>
                              {option.label}
                            </li>
                          )}
                          value={tenantValue}
                          onChange={(event, value) => {
                            console.log(typeof value, value);
                            setTenantValue(value);
                          }}
                          loading={loading}
                          noOptionsText={
                            <MDButton
                              variant="gradient"
                              sx={{
                                padding: "12px 30px",
                                backgroundColor: "#388e3c",
                                color: "#ffffff",
                                borderRadius: "0.2rem",
                                "&:hover": {
                                  backgroundColor: "#919191",
                                  color: "#fff",
                                },
                                "&:disabled": {
                                  background: "#e0e0e0"
                                }
                              }}
                              onClick={() => {
                                const newTenant = prompt("Enter new tenant name:");
                                if (newTenant) {
                                  const newTenantOption = { id: 0, label: newTenant };
                                  setOptions((prevOptions) => [...prevOptions, newTenantOption]);
                                  setTenantValue(newTenantOption);
                                }
                              }}
                            >
                              Add New Tenant
                            </MDButton>
                          }
                        />
                      </MDBox>
                      
                      {tenantValue && tenantValue.id === 0 && (
                        <MDBox mt={2} mb={2}>
                          <Alert severity="info">
                            Please click save changes to generate new Tenant, otherwise the previous action won't be saved.
                          </Alert>
                        </MDBox>
                      )}
                      { tenantValue && tenantValue.id != 0 && (
                        <MDBox mt={1} mb={1} textAlign="left">
                          <MDButton
                            // href={`https://mgt.yeelim.org/#/tenants/${tenantValue.id}/edit`}
                            href={`https://mgt.yyproperty.org/#/tenants/${tenantValue.id}/edit`}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="gradient"
                            sx={{
                              padding: "12px 25px",
                              backgroundColor: "#388e3c",
                              color: "#ffffff",
                              borderRadius: "0.2rem",
                              "&:hover": {
                                backgroundColor: "#919191",
                                color: "#fff",
                              },
                              "&:disabled": {
                                background: "#e0e0e0"
                              },
                              "&:focus:not(:hover)": {
                                backgroundColor: "#388e3c"
                              }
                            }}
                            startIcon={<Icon>visibility</Icon>}
                            type="submit"
                          >
                            View / Edit Tenant
                          </MDButton>
                        </MDBox>
                      )}
                      <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton
                          variant="gradient"
                          sx={{
                            padding: "12px 30px",
                            backgroundColor: "#388e3c",
                            color: "#ffffff",
                            borderRadius: "0.2rem",
                            "&:hover": {
                              backgroundColor: "#919191",
                              color: "#fff",
                            },
                            "&:disabled": {
                              background: "#e0e0e0"
                            }
                          }}
                          startIcon={loadingButton && <CircularProgress color="inherit" size={20} />}
                          disabled={errors || loadingButton}
                          type="submit"
                        >
                          Save Lease
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      {error && <Alert severity="error">{error}</Alert>} 
    </Dialog>
  );
}

export default EditLeases;
